import React from "react";

import { DateProvider } from "../features/analyze/context";
import MyAccountPage from "../pages/account/MyAccountPage";

const AccountSettingPage = React.lazy(() => import("../pages/account/AccountSettingPage"));
const CurrentUserPlanPage = React.lazy(() => import("../pages/account/billing/CurrentUserPlanPage"));
const PromoteUpgradePage = React.lazy(() => import("../pages/account/PromoteUpgradePage"));
const UsagePage = React.lazy(() => import("../pages/account/usage/UsagePage"));
const ApiKeysPage = React.lazy(() => import("../pages/account/apiKeys/ApiKeysPage"));

const AccountRoutes = [
    {
        element: <DateProvider />,
        path: "",
        children: [
            {
                path: "billing",
                element: <MyAccountPage />,
                children: [
                    { element: <CurrentUserPlanPage />, path: "current-plan" },
                    { element: <UsagePage />, path: "usage" },
                    { element: <ApiKeysPage />, path: "api-keys" },
                ],
            },
        ],
    },
    { element: <AccountSettingPage />, path: "settings" },
    { element: <PromoteUpgradePage />, path: "promote-upgrade" },
];

export default AccountRoutes;
