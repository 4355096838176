import { TypographyProps } from "@mui/material";

import classes from "./HighLightText.module.scss";
interface IProps extends TypographyProps {
    text: string;
}

const HighLightText = ({ text }: IProps) => {
    return <div className={classes.highlight}>{text}</div>;
};
export default HighLightText;
