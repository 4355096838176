import { useNavigate, useParams } from "react-router-dom";

import { customToast } from "../../../components/notify";
import { usePageConfig } from "../../../hooks";
import { tryCatchAsync } from "../../../utils";
import { KNOWLEDGE_PAGE_LIMIT } from "../constant.ts";
import { useBindKnowledgeMutation, useGetUnbindKnowledgesQuery } from "../services";
import { IKnowledgeInfo } from "../types";

const useMineList = () => {
    const { projectId } = useParams();
    const navigate = useNavigate();

    const { handleSearch, handlePageChange, currentPage, currentSearch } = usePageConfig({
        pageLimit: KNOWLEDGE_PAGE_LIMIT,
        projectId: projectId ?? "",
    });

    // ================ API ================
    const { data: myKs, isLoading: myKsLoading } = useGetUnbindKnowledgesQuery(
        {
            page: currentPage,
            limit: KNOWLEDGE_PAGE_LIMIT,
            filter: currentSearch,
            order_by: "updated_at desc",
        },
        { refetchOnMountOrArgChange: true }
    );
    const [bindKToProject] = useBindKnowledgeMutation();

    // ================ Handler ================

    const handleBindToProject = async (knowledgeId: string | undefined) => {
        if (!projectId || !knowledgeId) return;
        const [, error] = await tryCatchAsync(() =>
            bindKToProject({
                knowledge_id: knowledgeId,
                project_id: projectId,
            }).unwrap()
        );
        if (error) {
            customToast.error("binding failed");
            return;
        }
        customToast.success("binding success");
    };
    const handleCheckbox = (selectedKnowledge: IKnowledgeInfo) => {
        if (!projectId) return;
        handleBindToProject(selectedKnowledge?.id);
    };

    const handleClickKnowledge = (knowledgeId: string) => {
        if (projectId && knowledgeId) {
            const path = `/projects/${projectId}/knowledge`;
            const backUrl = `${path}/mine-knowledge`;
            navigate(path + `/${knowledgeId}/knowledge-records`, { state: backUrl });
        }
    };

    return {
        pageIsLoading: myKsLoading,
        showKnowledgeList: myKs?.data.records,
        handleCheckbox,
        handleSearch,
        handleClickKnowledge,
        pageCount: Math.ceil((myKs?.data.total_count ?? 0) / KNOWLEDGE_PAGE_LIMIT),
        currentPage,
        handlePageChange,
    };
};

export default useMineList;
