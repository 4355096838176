import type { SetValueConfig } from "react-hook-form";

import { LANGUAGE_OPTIONS } from "../features/characters/constants";

// * it should be followed by MIME type
// * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const MIME_TYPES: Record<string, string> = {
    pdf: "application/pdf",
    txt: "text/plain",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    mp3: "audio/mpeg",
    mpeg: "video/mpeg",
    mp4: "video/mp4",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    wav: "audio/wav",
    jpg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    webp: "image/webp",
};
export const FORM_STATE_OPTIONS: SetValueConfig = {
    shouldValidate: true,
    shouldDirty: true,
    shouldTouch: true,
};
export const BASE_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5005";

export const SALE_API_URL = process.env.REACT_APP_SALES_URL;

export const AMAZE_DASHBOARD_WIDGET_STYLE_OVERRIDES = {
    "base-font-size": "16px",
    "message-font-size": "16px",
    "mobile-avatar-size": "150px",
    primary: "#5D2EFB",
};

// > amaze 後台有用 chat-widget 時 會需要開放全語言來測試
export const AMAZE_DASHBOARD_WIDGET_CONFIG_FOR_APP = {
    openLanguageSelector: true,
    languageOptions: LANGUAGE_OPTIONS,
};
