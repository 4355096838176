import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { ScrollBox } from "../../../../components/box";
import { MButton } from "../../../../components/button";
import { HeadingWithInfo } from "../../../../components/heading";
import { CharacterCard } from "../../../characters/components/characterCard";
import { useGetAllProjectCharactersQuery } from "../../../characters/services";
import styles from "./PCharacters.module.scss";

const PCharacters = () => {
    const { projectId } = useParams();

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { data: characterList } = useGetAllProjectCharactersQuery(
        { page: 1, limit: 100, project_id: projectId as string },
        { skip: !projectId, refetchOnMountOrArgChange: true }
    );

    return (
        <div className={styles.section}>
            <div className={styles.heading}>
                <HeadingWithInfo title={t("DASHBOARD.MY_CHARACTERS")} info={t("DASHBOARD.MY_CHARACTERS_HELP")} />
                <div className={styles.actions}>
                    <MButton
                        btnText={t("CH.Create_Character")}
                        icon={<Add />}
                        sx={{ maxWidth: "160px" }}
                        fullWidth
                        handleClick={() =>
                            navigate(`/projects/${projectId}/characters/create-character`, { state: projectId })
                        }
                    />
                </div>
            </div>
            <ScrollBox key={projectId}>
                {characterList?.data.records &&
                    characterList?.data?.records.map((item) => {
                        return (
                            <CharacterCard
                                characterId={item.id}
                                characterName={item.name}
                                avatarUrl={item.avatar_file_name}
                                cardWidth='224px'
                                key={item.id}
                            />
                        );
                    })}
            </ScrollBox>
        </div>
    );
};
export default PCharacters;
