import MainWrapper from "../components/layout/MainWrapper";
import MAlert from "../components/notify/MAlert";
import useTabTitle from "../hooks/useTabTitle";

const NotFound = () => {
    useTabTitle("Oops");

    return (
        <MainWrapper>
            <MAlert severity='error'>Sorry the page was not found {`>///<`}</MAlert>
        </MainWrapper>
    );
};
export default NotFound;
