import { IconButton, Paper, SvgIcon } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as FbIcon } from "../../../../assets/icons/facebook.svg";
import { ReactComponent as GearIcon } from "../../../../assets/icons/gear.svg";
import { ReactComponent as KioskIcon } from "../../../../assets/icons/Kiosk2.svg";
import { ReactComponent as LineIcon } from "../../../../assets/icons/line.svg";
import { ReactComponent as WebIcon } from "../../../../assets/icons/WEB.svg";
import { MButton } from "../../../../components/button";
import { PageLoader } from "../../../../components/loader";
import { customToast } from "../../../../components/notify";
import { useBindCharacterMutation } from "../../services";
import { CharacterAvatar } from "../characterInfo";
import styles from "./CharacterCard.module.scss";

interface IProps {
    characterId: string;
    characterName: string;
    avatarUrl: string;
    cardWidth?: string;
    isPublicMode?: boolean;
}
const CharacterCard = ({ characterId, characterName, avatarUrl, cardWidth, isPublicMode = false }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [bindCharacter, { isLoading, isSuccess }] = useBindCharacterMutation();

    const prefixPath = `/projects/${projectId}/characters/${characterId}`;

    const handleCharacterClick = () => navigate(`${prefixPath}/character-info?lang=zh-TW`, { state: projectId });

    const handleChatClick = () => navigate(`${prefixPath}/chatroom`, { state: projectId });

    const handleFbClick = () => navigate(`${prefixPath}/bot-settings/fb`, { state: projectId });

    const handleLineClick = () => navigate(`${prefixPath}/bot-settings/line`, { state: projectId });
    const handleWidgetClick = () => navigate(`${prefixPath}/bot-settings/web-widget`, { state: projectId });
    const handleAppClick = () => navigate(`${prefixPath}/bot-settings/app`, { state: projectId });

    const handleAddClick = async () => {
        if (projectId && characterId) {
            await bindCharacter({
                project_id: projectId,
                character_id: characterId,
            });
        }
    };
    useEffect(() => {
        if (isSuccess) {
            customToast.success("Bind Success!");
            navigate(-1);
        }
    }, [isSuccess, navigate]);
    return (
        <Paper className={styles.card}>
            <PageLoader loading={isLoading} />
            <div className={styles.cardBody}>
                <div className={styles.content} onClick={handleCharacterClick}>
                    <p className={styles.characterName}>{characterName}</p>
                    <div>
                        <CharacterAvatar avatarUrl={avatarUrl} />
                        <div className={styles.editMask}>
                            <SvgIcon
                                component={GearIcon}
                                className={styles.gear}
                                inheritViewBox
                                sx={{ fontSize: 48 }}
                            />
                        </div>
                    </div>
                </div>
                {!isPublicMode && (
                    <div className={styles.actions}>
                        <IconButton onClick={handleLineClick}>
                            <SvgIcon component={LineIcon} inheritViewBox sx={{ fontSize: 34 }} />
                        </IconButton>
                        <IconButton onClick={handleWidgetClick}>
                            <SvgIcon component={WebIcon} inheritViewBox sx={{ fontSize: 34 }} />
                        </IconButton>
                        <IconButton onClick={handleAppClick}>
                            <SvgIcon component={KioskIcon} inheritViewBox sx={{ fontSize: 34 }} />
                        </IconButton>
                        <IconButton onClick={handleFbClick}>
                            <SvgIcon component={FbIcon} inheritViewBox sx={{ fontSize: 34, fill: "#0477E2" }} />
                        </IconButton>
                    </div>
                )}

                <div
                    className={styles.footer}
                    // mt={1} sx={{ display: "flex", width: "auto" }}
                >
                    {!isPublicMode && <MButton handleClick={handleChatClick} btnText={t("COMMON.CHAT")} />}
                    {isPublicMode && <MButton handleClick={handleAddClick} btnText={t("COMMON.ADD")} />}
                </div>
            </div>
        </Paper>
    );
};
export default CharacterCard;
