import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { selectCurrentUser } from "../../features/authentication/services";
import { useGetAllProjectsQuery } from "../../features/projects/services";
import { useAppSelector } from "../../hooks";
import MainWrapper from "./MainWrapper";

const ProjectLayout = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const currentUser = useAppSelector(selectCurrentUser);
    const isAmzUser = !currentUser?.enterpriseAccountId;

    const { data: projectsList } = useGetAllProjectsQuery({ page: 1, limit: 100 }, { skip: !!projectId });

    useEffect(() => {
        if (!projectId && projectsList?.data && projectsList?.data?.records?.length > 0) {
            navigate(`/projects/${projectsList?.data.records[0]?.id}`);
        } else if (currentUser?.isFirstTimeLogin && isAmzUser) {
            navigate("/init-project");
        }
    }, [currentUser, projectsList, navigate, projectId, isAmzUser]);

    return (
        <MainWrapper>
            <Outlet />
        </MainWrapper>
    );
};
export default ProjectLayout;
