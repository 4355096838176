import { isRejectedWithValue, type Middleware, type MiddlewareAPI } from "@reduxjs/toolkit";
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { captureMessage } from "@sentry/react";
import { Mutex } from "async-mutex";
import { AuthTokens } from "aws-amplify/auth";

import { BASE_URL, SALE_API_URL } from "../../constants";
import { postEnterpriseLogin, postLogout, postSalesLogin } from "../../features/authentication/services";
import { awsSignOut, currentSession } from "../../lib/aws";
import { getLocalValueByKey } from "../../utils";
import { RootState } from "../store";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: "include",
    prepareHeaders: (headers, api) => {
        const { getState } = api;
        const isLogin = (getState() as RootState).auth.isLogin;
        const token = getLocalValueByKey("amaze_accessToken") || getLocalValueByKey("sale_accessToken") || null;

        // 如果 token 跟 cookie 同時存在給後端，後端會優先判斷 cookie
        if (isLogin && token) {
            headers.set("Authorization", "Bearer " + token);
        }
        return headers;
    },
});

const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    await mutex.waitForUnlock();

    let result = await baseQuery(args, api, extraOptions);

    //const errMsg: string | undefined = (result?.error?.data as { message?: string })?.message;

    // accessToken expired handling
    if (result?.error && result?.error?.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const amazeRefreshToken = localStorage.getItem("amaze_refreshToken") || null;
                const saleRefreshToken = localStorage.getItem("sale_refreshToken") || null;
                const isEnterpriseAccountLogin = !!amazeRefreshToken;
                const isSaleAccountLogin = !!saleRefreshToken;

                if (isEnterpriseAccountLogin) {
                    const res = await baseQuery(
                        {
                            url: "/token_refresh",
                            method: "POST",
                            body: { refresh_token: amazeRefreshToken },
                        },
                        api,
                        extraOptions
                    );
                    const refreshResult = res?.data as { access_token: string; refresh_token: string };
                    if (refreshResult?.access_token && refreshResult?.refresh_token) {
                        api.dispatch(
                            postEnterpriseLogin({
                                accessToken: refreshResult?.access_token,
                                refreshToken: refreshResult?.refresh_token,
                            })
                        );
                        result = await baseQuery(args, api, extraOptions);
                    } else {
                        api.abort();
                        apiSlice.util.resetApiState();
                        api.dispatch(postLogout());
                    }
                } else if (isSaleAccountLogin) {
                    // 銷售後台登入
                    try {
                        const res = (await baseQuery(
                            {
                                url: `${SALE_API_URL}/panel_token_refresh`,
                                method: "POST",
                                body: { refresh_token: saleRefreshToken },
                            },
                            api,
                            extraOptions
                        )) as { data: { data: { access_token: string; refresh_token: string } } };

                        const refreshResult = res.data.data;

                        if (refreshResult?.access_token && refreshResult?.refresh_token) {
                            api.dispatch(
                                postSalesLogin({
                                    accessToken: refreshResult?.access_token,
                                    refreshToken: refreshResult?.refresh_token,
                                })
                            );
                            result = await baseQuery(args, api, extraOptions);
                        } else {
                            api.abort();
                            apiSlice.util.resetApiState();
                            api.dispatch(postLogout());
                        }
                    } catch (error) {
                        api.abort();
                        apiSlice.util.resetApiState();
                        api.dispatch(postLogout());
                    }
                } else {
                    const sessionRes: AuthTokens | null = await currentSession();
                    const awsAccessToken = sessionRes?.accessToken.toString();

                    if (awsAccessToken) {
                        await baseQuery(
                            {
                                url: "/signin",
                                method: "POST",
                                body: { access_token: awsAccessToken },
                            },
                            api,
                            extraOptions
                        );
                        result = await baseQuery(args, api, extraOptions);
                    } else {
                        api.abort();
                        apiSlice.util.resetApiState();
                        api.dispatch(postLogout());
                        await awsSignOut();
                    }
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};

export const apiSlice = createApi({
    baseQuery: baseQueryWithReAuth,
    tagTypes: [
        "Auth",
        "Knowledge",
        "Project",
        "Character",
        "ProjectCharacter",
        "Game",
        "ProjectGame",
        "ProjectMember",
        "KnowledgeRecord",
        "Voice",
        "Bot",
        "AzureAudio",
        "LineAcc",
        "IsBindLine",
        "JobStatus",
        "DefaultPrompts",
        "HintTexts",
        "HintText",
        "FbAcc",
        "IsBindFb",
        "VoiceKeywords",
        "VoiceKeyword",
        "VoiceKeywordsJob",
        "ServiceList",
        "Contract",
        "KnowledgeExport",
        "ProjectData",
        "UsageData",
        "ChatHistory",
        "ScriptTest",
        "ScriptTestReport",
        "ApiKey",
        "ChatWidgetSetting",
        "HomophoneFix",
        "CharacterVoices",
        "OrgItems",
        "OrgCategory",
        "SalesOrgs",
        "SalesCategories",
        "SalesItems",
        "Items",
        "SalesCategoryItems",
        "SalesCategory",
    ],
    endpoints: () => ({}),
});

export const rtkQueryErrorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action) && action?.payload?.originalStatus !== 401) {
        // eslint-disable-next-line no-console -- for debugging
        console.log("🐑 ~ fetch api error:", action?.payload?.data || "API ERROR");
        captureMessage(action?.payload?.data || "API ERROR");
    }

    return next(action);
};
