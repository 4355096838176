import { useParams } from "react-router-dom";

import { customToast } from "../../../components/notify/NotifyContainer";
import { useIsProjectOwner } from "../../projects/hooks";
import { useDownloadChatHistoryMutation } from "../services/charactersApiSlice";

const useChatHistoryDownload = () => {
    const [fetchDownload] = useDownloadChatHistoryMutation();
    const { characterId, projectId } = useParams();
    const isProjectOwner = useIsProjectOwner({ projectId });

    const handleDownload = async () => {
        try {
            if (characterId && projectId) {
                const res = await fetchDownload({ character_id: characterId, project_id: projectId }).unwrap();
                if (res?.status === 1 && res?.data?.file_url) {
                    window.open(res?.data?.file_url, "_blank");
                }
            }
        } catch (err) {
            customToast.error("Something wrong. Please try again later.");
        }
    };
    return { handleDownload, isProjectOwner };
};
export default useChatHistoryDownload;
