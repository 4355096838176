import { Box } from "@mui/material";
import { FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MButton } from "../../../../components/button";
import { FormInput } from "../../../../components/form";
import { ICharacterHintInput, IHintRecord } from "../../types";
import classes from "./HintForm.module.scss";
import HintList from "./HintList";

interface IProps {
    isEditHintGroup: boolean;
    methods: UseFormReturn<ICharacterHintInput>;
    handleFormSubmit: SubmitHandler<ICharacterHintInput>;
    formIsValid: boolean;
    formIsDirty: boolean;
    hintLimit: boolean;
    filteredData: IHintRecord[];
    handleEditCancel: () => void;
    handleHintEdit: (id: string) => void;
    handleHintDelete: (recordId: string) => Promise<void>;
}
const HintForm = ({
    methods,
    handleFormSubmit,
    formIsDirty,
    formIsValid,
    hintLimit,
    filteredData,
    handleEditCancel,
    handleHintEdit,
    handleHintDelete,
    isEditHintGroup,
}: IProps) => {
    const { t } = useTranslation();

    return (
        <FormProvider {...methods}>
            <Box
                component='form'
                noValidate
                autoComplete='on'
                onSubmit={methods.handleSubmit(handleFormSubmit)}
                margin={0}
                gap={2}
                className={classes.hintForm}
            >
                <FormInput
                    name='hintQ'
                    variant='outlined'
                    multiline={false}
                    required={true}
                    fullWidth
                    hiddenLabel
                    placeholder={t("CH.Hint_Text_Q_Placeholder") ?? "hint text"}
                    sx={{ mt: 1, height: "7rem" }}
                    label={t("CH.Hint_Text_Q") ?? "hint text"}
                />
                <FormInput
                    name='hintA'
                    variant='outlined'
                    multiline={true}
                    fullWidth
                    rows={4}
                    required={false}
                    hiddenLabel
                    placeholder={t("CH.Hint_Text_A_Placeholder") ?? "answer text"}
                    sx={{ height: "13rem" }}
                    label={t("CH.Hint_Text_A") ?? "answer text"}
                />

                <div className={classes.btnAction}>
                    {!hintLimit && <div>{t("CH.Hint_PromptGroupLimit")}</div>}
                    {isEditHintGroup && (
                        <MButton
                            btnText={t("COMMON.Cancel")}
                            sx={{ ml: 2, whiteSpace: "nowrap", color: "#909090", background: "#eaeaea" }}
                            handleClick={handleEditCancel}
                        />
                    )}
                    <MButton
                        btnText={t("CH.Hint_Save")}
                        type='submit'
                        sx={{ ml: 2, whiteSpace: "nowrap" }}
                        disabled={!formIsDirty || !formIsValid || (!hintLimit && !isEditHintGroup)}
                    />
                </div>
                <HintList data={filteredData || []} handleEdit={handleHintEdit} handleDelete={handleHintDelete} />
            </Box>
        </FormProvider>
    );
};
export default HintForm;
