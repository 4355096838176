import { Search } from "@mui/icons-material";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import clsx from "clsx";
import { KeyboardEventHandler, useState } from "react";

import { IFilter } from "../../types/interfaces";
import styles from "./SearchBox.module.scss";

interface IProps {
    className?: string;
    placeHolder: string;
    onSearch: (searchInput: IFilter) => void;
    searchField: string;
    isCaseSensitive: boolean;
}
const SearchBox = ({ className, placeHolder, onSearch, searchField, isCaseSensitive }: IProps) => {
    const [searchInput, setSearchInput] = useState<string>("");
    const searchOperator = isCaseSensitive ? "like" : "ilike";
    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key !== "Enter") return;
        if (searchInput.trim().length > 0) {
            onSearch({ field: searchField, operator: searchOperator, value: searchInput.trim() });
            setSearchInput("");
            return;
        }
        onSearch({ field: searchField, operator: searchOperator, value: "" });
    };
    return (
        <OutlinedInput
            className={clsx(className, styles.searchInput)}
            placeholder={placeHolder}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleKeyDown}
            fullWidth
            endAdornment={
                <InputAdornment position='end'>
                    <IconButton
                        onClick={() => {
                            if (searchInput.trim().length === 0) {
                                onSearch({ field: searchField, operator: "ilike", value: "" });
                                return;
                            }
                            onSearch({ field: searchField, operator: "ilike", value: searchInput.trim() });
                            setSearchInput("");
                        }}
                        edge='end'
                    >
                        <Search />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};
export default SearchBox;
