export enum SOCIAL_TYPES {
    none,
    discord,
    facebook,
    line,
    mattermost,
    telegram,
    web,
    kiosk,
    //whatsapp,
    autoTest = 100, // only for amaze 後台使用，其他的必須經過審核才能夠正常使用 amazeKey
}
