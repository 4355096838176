import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { MainTabs } from "../../../../components/tab";
import { ILinkTabProps } from "../../../../types/interfaces";
import { useCharacterHint } from "../../hooks";
import styles from "./Hint.module.scss";
import HintForm from "./HintForm";

const hintTabsRoutes = [
    { labelKey: "CH.Hint_zh", href: "zh-TW" },
    { labelKey: "CH.Hint_en", href: "en-US" },
    { labelKey: "CH.Hint_jp", href: "ja-JP" },
];

const Hint = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const navigate = useNavigate();
    const { projectId, characterId } = useParams();
    const [isFormReset, setIsFormReset] = useState(false);
    const [isEditHintGroup, setIsEditHintGroup] = useState(false);
    const [searchParams] = useSearchParams();
    const selectedLang = searchParams.get("lang") || "zh-TW";

    const findIdx = hintTabsRoutes.findIndex((route) => route.href === searchParams.get("lang"));

    const {
        filteredData,
        handleFormSubmit,
        methods,
        formIsValid,
        formIsDirty,
        hintLimit,
        handleEditCancel,
        handleHintEdit,
        handleHintDelete,
    } = useCharacterHint({ isFormReset, setIsFormReset, isEditHintGroup, setIsEditHintGroup, selectedLang });

    const handleClick = (tabRoute: ILinkTabProps) => {
        navigate(`/projects/${projectId}/characters/${characterId}/character-info?lang=${tabRoute.href}`, {
            state: projectId,
        });
        setIsFormReset(true);
    };
    const handleChange = (newValue: number) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        // if findIdx is not -1, means it's project dashboard index page, show current tab === Dashboard
        if (findIdx !== -1) {
            setCurrentTab(findIdx);
            return;
        }
        setCurrentTab(0);
    }, [findIdx, selectedLang]);
    return (
        <>
            <MainTabs
                currentTab={currentTab}
                tabRoutes={hintTabsRoutes}
                tabsOnClick={handleClick}
                handleChange={handleChange}
                className={styles.customTab}
            />

            <HintForm
                methods={methods}
                handleFormSubmit={handleFormSubmit}
                formIsValid={formIsValid}
                formIsDirty={formIsDirty}
                hintLimit={hintLimit}
                filteredData={filteredData}
                handleEditCancel={handleEditCancel}
                handleHintEdit={handleHintEdit}
                handleHintDelete={handleHintDelete}
                isEditHintGroup={isEditHintGroup}
            />
        </>
    );
};

export default Hint;
