import { PCharacters } from "./ProjectCharacters";
import { PMyKnowledge } from "./ProjectMyKnowledges";

const ProjectDashboard = () => {
    return (
        <div>
            <PCharacters />
            <PMyKnowledge />
        </div>
    );
};
export default ProjectDashboard;
