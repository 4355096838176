import { Box, Grid } from "@mui/material";
import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MButton } from "../../../../components/button";
import { FormInput, FormSelect, MSelect } from "../../../../components/form";
import { LOCALE_OPTIONS, MEMORY_OPTIONS } from "../../constants";
import { useCharacterForm } from "../../hooks";
import { Hints } from "../hint";
import AvatarInput from "./AvatarInput";
import styles from "./CharacterForm.module.scss";

interface IProps {
    isCreate: boolean;
}

const CharacterForm = ({ isCreate }: IProps) => {
    const { t } = useTranslation();
    const {
        handleSetAvatarFile,
        avatarFile,
        methods,
        handleFormSubmit,
        submitBtnLoading,
        //descWordCount,
        //isDescWordCountError,
        //handleUpgrade,
        promptsOptions,
        defaultPromptValue,
        onChangeSelectedPromptId,
    } = useCharacterForm({ isCreate });
    //-------------------------------------

    const { isDirty, isValid } = methods.formState;
    return (
        <Box>
            <FormProvider {...methods}>
                <Box
                    component='form'
                    noValidate
                    autoComplete='on'
                    onSubmit={methods.handleSubmit(handleFormSubmit)}
                    sx={{ margin: "0" }}
                >
                    <div className={styles.avatarWrapper}>
                        <AvatarInput handleSetAvatarFile={handleSetAvatarFile} avatarFile={avatarFile} />
                    </div>

                    <FormInput
                        name='name'
                        variant='outlined'
                        required={true}
                        fullWidth
                        label={t("CH.Name") ?? "name"}
                    />

                    <FormInput
                        name='description'
                        variant='outlined'
                        maxRows={4}
                        multiline={true}
                        minRows={2}
                        fullWidth
                        label={t("CH.Desc") ?? "Description"}
                        required={false}
                        sx={{ mt: 1.5 }}
                        placeholder={
                            t("CH.Description_Default") ||
                            "This field can add descriptions and notes about the character, such as: character usage occasions, character personality brief description..."
                        }
                    />
                    {isCreate && (
                        <FormSelect
                            variant='outlined'
                            name='language'
                            //onChange={() => {}}
                            options={LOCALE_OPTIONS}
                            label={t("CH.Voice_Language") || "Language"}
                        />
                    )}
                    <FormInput
                        name='prompt'
                        rows={7}
                        multiline={true}
                        variant='outlined'
                        required={false}
                        sx={{ mt: 1.5 }}
                        label={t("CH.Prompt") ?? "Prompt"}
                    />
                    {/*<div
                        className={isDescWordCountError ? `${styles.errorState}` : styles.countBar}
                        style={{ width: `${(descWordCount / PROMPT_WORD_LIMIT) * 100}%` }}
                    />*/}

                    <div className={styles.promptSection}>
                        {/*<span className={styles.hintLimit}>
                            {t("COMMON.Word_Limit")} ({descWordCount}/{PROMPT_WORD_LIMIT})
                        </span>*/}
                        {/*<button className={styles.hintLink} onClick={handleUpgrade}>
                            {t("COMMON.Word_Unlimit")}
                        </button>*/}
                        <Grid container spacing={1} mt={1}>
                            <Grid item xs={12} md={6}>
                                <span style={{ fontSize: "20px" }}>&lowast;</span>
                                <span className={styles.selectPromptCH}>{t("CH.Select_PromptCH")}</span>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MSelect
                                    variant='outlined'
                                    name='promptSelect'
                                    value={defaultPromptValue}
                                    onChange={onChangeSelectedPromptId}
                                    options={promptsOptions}
                                    className={styles.customSelect2}
                                    label={t("CH.Prompt_SelectPrompt") || "Select Prompt"}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    {!isCreate && (
                        <FormSelect
                            name='memory_type'
                            options={MEMORY_OPTIONS}
                            sx={{ mt: 2 }}
                            label={t("KNOWLEDGE.Knowledge_Category") ?? "category"}
                            variant='outlined'
                        />
                    )}

                    <FormInput
                        name='greeting'
                        variant='outlined'
                        maxRows={4}
                        multiline={true}
                        minRows={3}
                        sx={{ mt: 2 }}
                        required={true}
                        label={t("CH.Greeting") ?? "Greeting text"}
                        placeholder={t("CH.Greeting_Default") || "This field can add the character's greeting?"}
                    />

                    <div className={isCreate ? styles.actionWrapper : `${styles.actionWrapper} ${styles.translateY}`}>
                        <MButton
                            btnText={t("COMMON.SAVE")}
                            type='submit'
                            sx={{ px: "60px" }}
                            loading={submitBtnLoading}
                            disabled={!isDirty || (isDirty && !isValid)}
                        />
                    </div>
                </Box>
            </FormProvider>
            {!isCreate && <Hints />}
        </Box>
    );
};
export default CharacterForm;
