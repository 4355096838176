import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import MButton from "../../../components/button/MButton";
import FormInput from "../../../components/form/FormInput";
import useEnterpriseLogin from "../hooks/useEnterpriseLogin";
import styles from "./EnterpriseLoginForm.module.scss";
const EnterpriseLoginForm = () => {
    const { t } = useTranslation();

    const { methods, onSubmit } = useEnterpriseLogin();

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.signInform}>
                <div className={styles.signInArea}>
                    <div className={styles.inputBox}>
                        <FormInput name='id' label={t("COMMON.Id")} required={true} className={styles.input} />
                        <FormInput
                            name='password'
                            label={t("COMMON.Password")}
                            required={true}
                            className={styles.input}
                            type='password'
                        />
                    </div>
                </div>
                <div className={styles.actionBox}>
                    <div className={styles.submitBox}>
                        <MButton btnText={t("COMMON.Login")} disabled={!methods.formState.isValid} type='submit' />
                    </div>
                    <div className={styles.hintBox}>
                        <div className={styles.textLink}>
                            <Link to={"/login"} className={styles.loginLink}>
                                {t("COMMON.Amaze_login")}
                            </Link>
                        </div>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};
export default EnterpriseLoginForm;
