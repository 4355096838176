import { Delete, EditRounded } from "@mui/icons-material";
import { ListItem, ListItemText, SvgIcon } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as HintIconA } from "../../../../assets/icons/icon_A.svg";
import { ReactComponent as HintIconQ } from "../../../../assets/icons/icon_Q.svg";
import { MButton } from "../../../../components/button";
import { RefactorRegularModal } from "../../../../components/dialog";
import styles from "./HintForm.module.scss";

interface IProps {
    data: {
        id: string;
        prompt: string;
        answer: string;
    }[];
    handleEdit: (id: string) => void;
    handleDelete: (id: string) => void;
}
const HintList = ({ data, handleEdit, handleDelete }: IProps) => {
    const { t } = useTranslation();
    const { LoadingBtn, Container, Title, Actions, Cancel, Confirm } = RefactorRegularModal;
    return (
        <div className={styles.hintList}>
            {data.length >= 0 &&
                data.map((item) => {
                    return (
                        <Fragment key={item.id}>
                            <div className={styles.hintItem}>
                                <div className={styles.iconWrapper}>
                                    <SvgIcon
                                        component={HintIconQ}
                                        className={styles.icon}
                                        inheritViewBox
                                        sx={{ fontSize: 24, fill: "#e6deff" }}
                                    />
                                    {item.answer.length > 0 && (
                                        <SvgIcon component={HintIconA} inheritViewBox sx={{ fontSize: 24 }} />
                                    )}
                                </div>
                                <div className={styles.hintTitle}>{item.prompt}</div>
                                <div className={styles.btnWrapper}>
                                    <MButton
                                        variant='text'
                                        btnText=''
                                        icon={<EditRounded />}
                                        color='primary'
                                        onClick={() => {
                                            handleEdit(item.id);
                                        }}
                                    />
                                    <div>
                                        <RefactorRegularModal>
                                            <LoadingBtn
                                                color='warning'
                                                startIcon={<Delete />}
                                                classes={{ root: styles.deleteBtn }}
                                            />
                                            <Container>
                                                <Title>{t("CH.Delete_PromptGroup")}</Title>
                                                <Actions>
                                                    <Cancel />
                                                    <Confirm onClick={() => handleDelete(item.id)} color='warning' />
                                                </Actions>
                                            </Container>
                                        </RefactorRegularModal>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            {data.length === 0 && (
                <ListItem>
                    <ListItemText secondary={t("CH.Hint_None")} />
                </ListItem>
            )}
        </div>
    );
};

export default HintList;
