import React from "react";

import { DateProvider } from "../features/analyze/context";
import { CharactersEditLayout } from "../features/knowledges/components/layout";

const ChatroomSettingPage = React.lazy(() => import("../pages/characters/ChatroomSettingPage"));
const CharacterSettingsPage = React.lazy(() => import("../pages/characters/CharacterSettingsPage"));
const ChatHistoryPage = React.lazy(() => import("../pages/characters/ChatHistoryPage"));
const AppSettingsPage = React.lazy(() => import("../pages/botSettings/AppSettingsPage"));
const FbSettingsPage = React.lazy(() => import("../pages/botSettings/FbSettingsPage"));
const LineSettingsPage = React.lazy(() => import("../pages/botSettings/LineSettingsPage"));
const WebWidgetSettingsPage = React.lazy(() => import("../pages/botSettings/WebWidgetSettingsPage"));
const CreateCharacterPage = React.lazy(() => import("../pages/characters/CreateCharacterPage"));
const CVoiceSettingPage = React.lazy(() => import("../pages/characters/CVoiceSettingPage"));
const CharacterInfoPage = React.lazy(() => import("../pages/characters/CharacterInfoPage"));
const ChatroomPage = React.lazy(() => import("../pages/characters/ChatroomPage"));

const CharacterRoutes = [
    { element: <CreateCharacterPage />, path: "create-character" },
    {
        path: ":characterId",
        element: <CharactersEditLayout />,
        children: [
            {
                path: "character-info",
                element: <CharacterInfoPage />,
            },
            {
                path: "voice-setting",
                element: <CVoiceSettingPage />,
            },
            {
                path: "chatroom-setting",
                element: <ChatroomSettingPage />,
            },
            {
                path: "character-setting",
                element: <CharacterSettingsPage />,
            },
            {
                path: "character-chat-history",
                element: <DateProvider />,
                children: [{ path: "", element: <ChatHistoryPage /> }],
            },
        ],
    },

    {
        path: ":characterId",
        children: [
            { path: "chatroom", element: <ChatroomPage /> },
            {
                path: "bot-settings",
                children: [
                    {
                        element: <FbSettingsPage />,
                        path: "fb",
                    },
                    {
                        element: <LineSettingsPage />,
                        path: "line",
                    },
                    {
                        element: <WebWidgetSettingsPage />,
                        path: "web-widget",
                    },
                    {
                        element: <AppSettingsPage />,
                        path: "app",
                    },
                ],
            },
        ],
    },
];

export default CharacterRoutes;
