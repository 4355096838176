import { apiSlice } from "../../../services/api";
import { IGetListRequest, IGetListResponse, IGetResponse } from "../../../types/interfaces";
import {
    ICreateProjectReq,
    ICreateProjectRes,
    IDeleteProjectReq,
    IInitProjectReq,
    IProjectTypes,
    IQueryProjectParams,
    IUpdateProjectReq,
} from "../types/interfaces";

export const projectsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        initFirstProject: builder.mutation<ICreateProjectRes, IInitProjectReq>({
            // create new user gift
            query: (reqData) => ({
                url: "/init_project",
                method: "POST",
                body: { ...reqData },
                //timeout: 1000 * 60 * 10,
            }),
            invalidatesTags: ["Project"],
        }),
        createProject: builder.mutation<{ success: boolean; projects: string }, ICreateProjectReq>({
            query: (reqData) => ({
                url: "/create_project",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Project"],
        }),
        updateProject: builder.mutation<{ success: boolean; projects: string }, IUpdateProjectReq>({
            query: (reqData) => ({
                url: "/project/update_record",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Project"],
        }),
        deleteProject: builder.mutation<{ success: boolean }, IDeleteProjectReq>({
            query: (reqData) => ({
                url: "/project/delete_project",
                method: "DELETE",
                body: { ...reqData },
            }),
            invalidatesTags: ["Project"],
        }),
        getAllProjects: builder.query<IGetListResponse<IProjectTypes[]>, IGetListRequest>({
            query: (reqData) => ({
                url: "/project/list_records",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["Project"],
        }),
        getProject: builder.query<IGetResponse<IProjectTypes>, IQueryProjectParams>({
            query: (params) => ({
                url: "/project/get_record",
                params,
            }),
            providesTags: ["Project"],
        }),
    }),
});

export const {
    useInitFirstProjectMutation,
    useGetAllProjectsQuery,
    useCreateProjectMutation,
    useDeleteProjectMutation,
    useGetProjectQuery,
    useUpdateProjectMutation,
} = projectsApiSlice;
