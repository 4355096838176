import { Info } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";

interface IProps {
    title: string;
    info: string;
}
const HeadingWithInfo = ({ title, info }: IProps) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", maxWidth: "500px" }}>
            <Typography
                fontSize='2rem'
                fontWeight='bold'
                sx={{ color: (theme) => theme.palette.primary.dark }}
                mr={0.5}
            >
                {title}
            </Typography>
            <Tooltip title={info} enterTouchDelay={0}>
                <Info sx={{ color: (theme) => theme.palette.primary.dark }} />
            </Tooltip>
        </Box>
    );
};
export default HeadingWithInfo;
