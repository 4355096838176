import clsx from "clsx";
import { CSSProperties } from "react";
import { useParams } from "react-router-dom";

import { ChatWidgetRoom } from "../../../../components/chatroom";
import { BouncingDots } from "../../../../components/loader";
import { AMAZE_DASHBOARD_WIDGET_CONFIG_FOR_APP, AMAZE_DASHBOARD_WIDGET_STYLE_OVERRIDES } from "../../../../constants";
import { CHAT_WIDGET_VERSION } from "../../../../types/enum";
import { useGenerateChatWidgetConfig } from "../../hooks";
import { useGetAllProjectCharactersQuery } from "../../services";
import styles from "./Chatroom.module.scss";

interface IProps {
    chatBoxClass: CSSProperties;
}

const Chatroom = ({ chatBoxClass }: IProps) => {
    const { characterId, projectId } = useParams();
    const allowSelectCharacter = !characterId;

    const { data: projectCharacters } = useGetAllProjectCharactersQuery(
        { page: 1, limit: 100, project_id: projectId as string },
        { skip: !(allowSelectCharacter && projectId) }
    );
    const selectedCharacterId = allowSelectCharacter ? projectCharacters?.data?.records?.[0]?.id : characterId;

    const widgetConfig = useGenerateChatWidgetConfig({
        characterId: selectedCharacterId,
        customStyleOverrides: AMAZE_DASHBOARD_WIDGET_STYLE_OVERRIDES,
        selectorId: "chat-widget-mobile",
        configForApp: AMAZE_DASHBOARD_WIDGET_CONFIG_FOR_APP,
    });

    if (!selectedCharacterId) {
        return (
            <div className={styles.widgetLoader}>
                <p>Cannot get character id.</p>
            </div>
        );
    }

    return (
        <>
            {widgetConfig ? (
                <ChatWidgetRoom
                    chatWidgetVersion={CHAT_WIDGET_VERSION.MOBILE}
                    chatWidgetConfig={
                        allowSelectCharacter
                            ? {
                                  ...widgetConfig,
                                  base: { ...widgetConfig.base, voice_enabled: true, openVoiceInput: true },
                              }
                            : widgetConfig
                    }
                    chatBoxClass={clsx(styles.chatWidgetMobile, chatBoxClass)}
                />
            ) : (
                <div className={clsx(styles.widgetLoader)}>
                    <BouncingDots />
                </div>
            )}
        </>
    );
};
export default Chatroom;
