import DemoVideo from "./DemoVideo";
import styles from "./ToggleVideo.module.scss";

interface IProps {
    handleVideoToggle: () => void;
}

const ToggleVideo = ({ handleVideoToggle }: IProps) => {
    const VideoBackground = () => {
        return (
            <div>
                <div className={styles.videoBackground} onClick={handleVideoToggle}></div>;
            </div>
        );
    };

    return (
        <div className={styles.toggleVideo}>
            <VideoBackground />
            <DemoVideo handleVideoToggle={handleVideoToggle} />
        </div>
    );
};

export default ToggleVideo;
