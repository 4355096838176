import { useInuseKnowledgesExportMutation } from "../services";

interface IProps {
    project_id: string | undefined;
}

const useKnowledgeExport = ({ project_id }: IProps) => {
    const [exportKnowledge, { isLoading }] = useInuseKnowledgesExportMutation();

    const handleExport = async () => {
        if (project_id) {
            const res = await exportKnowledge({ project_id }).unwrap();
            if (res?.status === 1 && res?.data?.file_url) {
                window.open(res?.data?.file_url, "_blank");
            }
        }
    };

    return { handleExport, exportLoading: isLoading };
};

export default useKnowledgeExport;
