import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { customToast } from "../../../components/notify";
import { usePageConfig } from "../../../hooks";
import { tryCatchAsync } from "../../../utils";
import { INUSE_PAGE_LIMIT } from "../constant.ts";
import { useGetAllKnowledgesByProjectsQuery, useUnBindKnowledgeMutation } from "../services";
import { IKnowledgeInfo } from "../types";

const useInuseList = () => {
    const { projectId } = useParams();
    const navigate = useNavigate();

    const { handleSearch, handlePageChange, currentPage, currentSearch } = usePageConfig({
        pageLimit: INUSE_PAGE_LIMIT,
        projectId: projectId ?? "",
    });

    // ================ API ================
    const { data: projectKs, isLoading: pKsLoading } = useGetAllKnowledgesByProjectsQuery(
        {
            page: currentPage,
            limit: INUSE_PAGE_LIMIT,
            filter: currentSearch,
        },
        { skip: !projectId, refetchOnMountOrArgChange: true }
    );
    const [unBlindK] = useUnBindKnowledgeMutation();

    // ================ Data Processing ================

    const showKnowledgeList = useMemo(() => {
        const temp: IKnowledgeInfo[] = projectKs?.data.records || [];
        return temp.slice().sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
    }, [projectKs]);

    // ================ Handler ================

    const handleRemoveBind = async (projectKnowledgeId: string | undefined) => {
        if (!projectKnowledgeId) return;
        const [, error] = await tryCatchAsync(() => unBlindK({ id: projectKnowledgeId }).unwrap());
        if (error) {
            customToast.error("unbind failed");
            return;
        }
        customToast.success("unbind success");
    };
    const handleCheckbox = (selectedKnowledge: IKnowledgeInfo) => {
        if (!projectId) return;
        handleRemoveBind(selectedKnowledge?.project_knowledge_id);
    };

    const handleClickKnowledge = (knowledgeId: string) => {
        if (projectId && knowledgeId) {
            const path = `/projects/${projectId}/knowledge`;
            const backUrl = `${path}/inuse-knowledge`;
            navigate(path + `/${knowledgeId}/knowledge-records`, { state: backUrl });
        }
    };

    return {
        pageIsLoading: pKsLoading,
        showKnowledgeList,
        handleCheckbox,
        handleSearch,
        handleClickKnowledge,
        pageCount: Math.ceil((projectKs?.data.total_count ?? 0) / INUSE_PAGE_LIMIT),
        currentPage,
        handlePageChange,
    };
};

export default useInuseList;
