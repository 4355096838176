import { yupResolver } from "@hookform/resolvers/yup";
import { FormEvent, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { customToast } from "../../../components/notify";
import { KNOWLEDGE_PAGE_MODE } from "../../../types/enum";
import {
    useBindKnowledgeMutation,
    useCreateKnowledgeMutation,
    useUpdateKnowledgeMutation,
} from "../services/knowledgesApiSlice";
import { ICreateKnowledgeInput } from "../types";
import { KnowledgeInfoSchema } from "../validation";
import useKnowledge from "./useKnowledge";

const defaultKnowledgeInfoData = {
    title: "",
    description: "",
    source_file_url: "",
    source_knowledge_id: "",
    public: false,
    project_id: "",
    knowledge_response_type: 0,
    category: "銷售", // 暫時寫死為銷售,
};

interface IProps {
    isNewKnowledge: boolean;
}

const useMutateKnowledge = ({ isNewKnowledge }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { projectId, knowledgeId } = useParams();
    const { knowledgeInfo, setEditMode } = useKnowledge({
        knowledgeId,
        mode: KNOWLEDGE_PAGE_MODE.EDIT,
    });

    // ====== api ======
    const [createK, { isLoading: cKLoading }] = useCreateKnowledgeMutation();
    const [updateK, { isLoading: uKLoading, isSuccess: uKSuccess }] = useUpdateKnowledgeMutation();
    const [bindK, { isLoading: bKLoading }] = useBindKnowledgeMutation();

    // react hook form
    const methods = useForm<ICreateKnowledgeInput>({
        defaultValues: {
            ...defaultKnowledgeInfoData,
            project_id: projectId,
        },
        resolver: yupResolver(KnowledgeInfoSchema()),
        mode: "all",
    });
    const { handleSubmit } = methods;

    const handleKSubmit: SubmitHandler<ICreateKnowledgeInput> = async (data) => {
        let reqData = data;
        const newKnowledgeId = uuidv4();

        try {
            if (isNewKnowledge && projectId) {
                reqData = {
                    ...data,
                    id: newKnowledgeId,
                };
                const createKnowledgeRes = await createK(reqData).unwrap();
                await bindK({
                    project_id: projectId,
                    knowledge_id: newKnowledgeId,
                }).unwrap();
                customToast.success(t("COMMON.Successfully"));
                navigate(`/projects/${projectId}/knowledge/${createKnowledgeRes?.data?.id}/smart-generator`);
            } else {
                await updateK(reqData).unwrap();
            }
        } catch (err) {
            customToast.error("Failed");
        }
    };
    const handleEdit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        methods.handleSubmit(handleKSubmit)();
        setEditMode();
    };
    // const handleEdit = (event: MouseEvent<HTMLButtonElement>) => {
    //     event.preventDefault();
    //     methods.handleSubmit(handleKSubmit)();
    //     setEditMode();
    // };
    const handleCancel = () => {
        methods.reset();
        setEditMode();
    };

    useEffect(() => {
        if (uKSuccess) {
            customToast.success(t("COMMON.Successfully"));
            navigate(`/projects/${projectId}/knowledge/${knowledgeId}/knowledge-records`);
        }
    }, [uKSuccess, t, navigate, projectId, knowledgeId]);

    useEffect(() => {
        if (knowledgeInfo) {
            const defaultValues = {
                ...defaultKnowledgeInfoData,
                project_id: projectId,
                ...knowledgeInfo,
            };
            methods.reset(defaultValues);
        }
    }, [knowledgeInfo, projectId, methods]);

    return {
        handleEdit,
        handleCancel,
        isLoading: cKLoading || bKLoading || uKLoading,
        methods,
        handleSubmit,
        handleKSubmit,
    };
};
export default useMutateKnowledge;
