import { IRoleResourceRestriction } from "../types";

/**
   {
        roles: 輸入需要被限制的角色
        resource: 輸入需要被限制的資源
        action: 輸入需要被限制的動作
    },
 */
export const ROLE_RESOURCE_RESTRICTIONS: IRoleResourceRestriction[] = [
    {
        roles: ["user", "enterprise_user"],
        resource: "project",
        action: "create",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "query_params_on_project_setting",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "gptModel",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "serviceType",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "context",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "ragModel",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "dynamic_hint",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "chatWidgetSetting",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "temperature",
        action: "update",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "primary_language",
        action: "update",
    },
];
