import { USER_PERMISSION_CONFIG } from "../data";
import { ROLE_RESOURCE_RESTRICTIONS } from "../data/roleRestrictions";
import { AMAZE_ACTION, AMAZE_RESOURCE, ROLE } from "../types";

const DEFAULT_ROLE: ROLE = "user";

// 沒有在 USER_PERMISSION_CONFIG 裡面的 email 都會回傳 DEFAULT_ROLE
export const getUserRole = (email?: string) => {
    if (!email) return DEFAULT_ROLE;
    const userRole = USER_PERMISSION_CONFIG.find((user) => user.email === email)?.role;
    return userRole || DEFAULT_ROLE;
};

export const checkHasPermission = (role: ROLE | undefined, resource: AMAZE_RESOURCE, action: AMAZE_ACTION) => {
    if (!role) {
        return false;
    }
    const isRestricted = ROLE_RESOURCE_RESTRICTIONS.find(
        (policy) => policy.roles.includes(role) && policy.resource === resource && policy.action === action
    );

    return !isRestricted;
};
