import { Box } from "@mui/material";

import { ProjectDashboard } from "../../features/projects/components";
import useTabTitle from "../../hooks/useTabTitle";

const DashboardPage = () => {
    useTabTitle("Project Dashboard");

    return (
        <Box sx={{ mt: 4 }}>
            <ProjectDashboard />
        </Box>
    );
};
export default DashboardPage;
