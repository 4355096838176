import documentIcon from "../assets/icons/document.svg";
import imageIcon from "../assets/icons/image.svg";
import linkIcon from "../assets/icons/link.svg";
import videoIcon from "../assets/icons/video.svg";
import { MIME_TYPES } from "../constants";
import { ICON_TYPES } from "../types/enum/enum";

export const calcFileSize = (size: number): string => {
    if (size < 0) {
        throw new Error("Size must be a non-negative number");
    }

    const units = ["B", "KB", "MB", "GB", "TB"];
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
};

export const checkObjValueExists = (obj: object) => {
    return Object.values(obj).every((item) => item !== null && item !== undefined && item !== "");
};

export const mapMimeTypeKey = (mimeType: string): string | undefined => {
    const entry = Object.entries(MIME_TYPES).find(([key, value]) => value === mimeType);
    return entry ? entry[0] : undefined;
};
export const getMimeHelper = {
    // 將 MIME 類型映射為文件擴展名
    mapMimeTypeKey: (mimeType: string): string | undefined => {
        const entry = Object.entries(MIME_TYPES).find(([key, value]) => value === mimeType);
        return entry ? entry[0] : undefined; // 返回 MIME 類型的鍵（文件擴展名）
    },

    // 將 MIME 類型陣列轉換為文件擴展名陣列
    getFileExtensions: (mimeTypes: string[]): string[] => {
        return mimeTypes.map((mimeType) => getMimeHelper.mapMimeTypeKey(mimeType) || mimeType);
    },

    // 將文件擴展名陣列轉換為 MIME 類型陣列
    getMimeTypes: (extensions: string[]): string[] => {
        return extensions.map((ext) => MIME_TYPES[ext] || ext);
    },
};
export const isProduction = process.env.REACT_APP_ENV === "production";
export const isStaging = process.env.REACT_APP_ENV === "staging";

export const tryCatchAsync = async <T>(dataFetching: () => Promise<T>) => {
    try {
        const data = await dataFetching();
        return [data, null];
    } catch (err) {
        return [null, err];
    }
};

export const getIcon = (iconType: ICON_TYPES) => {
    const iconSrc = {
        video: videoIcon,
        document: documentIcon,
        image: imageIcon,
        link: linkIcon,
    };
    return iconSrc[iconType];
};

//only for voiceKeyword now
export const getLanguage = (lang: string) => {
    const normalizedLang = lang.toLowerCase();
    const mappingObj: Record<string, string> = {
        "en-us": "en",
        "ja-jp": "ja",
        "zh-tw": "zh",
        "vi-vn": "vi",
        "th-th": "th",
        "id-id": "id",
    };
    return mappingObj[normalizedLang] ?? "zh-TW";
};

export const getLocalValueByKey = (key: string): string | null => {
    const value = localStorage.getItem(key);
    return value !== "null" && value !== "undefined" && value !== "" ? value : null;
};
export const formatTime = (time: number) => {
    // time 須為"秒", e.g. 5 = 5 秒
    if (time < 0) {
        throw new Error("Time must be a non-negative number");
    }

    const seconds = Math.floor(time % 60);
    const minutes = Math.floor((time % 3600) / 60);
    const hours = Math.floor(time / 3600);

    const format = (value: number) => (value >= 10 ? value.toString() : `0${value}`);

    return {
        getSeconds: () => format(seconds),
        getMinutes: () => format(minutes),
        getHours: () => format(hours),
    };
};

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

const excludeBracketedTextRegex = /\s*\(.*?\)\s*/g;
export const translateLang = ({ locale, currentLang }: { locale: string; currentLang: string }) => {
    // 部份語言特殊處理
    if (currentLang === "en-US") {
        switch (locale) {
            case "zh-TW":
                return "Traditional Chinese";
            case "zh-CN":
                return "Simplified Chinese";
            case "ja-JP":
                return "Japanese";
            case "ko-KR":
                return "Korean";
            case "vi-VN":
                return "Vietnamese";
            case "th-TH":
                return "Thai";
            case "en-US":
                return "English";
            default:
                break;
        }
    }

    const name = new Intl.DisplayNames([currentLang], { type: "language", style: "short" }).of(locale) || "";
    return name.replace(excludeBracketedTextRegex, "");
};

export const getLocalTime = (isoString: string | undefined) => {
    if (isoString !== undefined) {
        const date = new Date(isoString);
        return new Intl.DateTimeFormat(undefined, {
            timeStyle: "medium",
            hour12: false, //18:48
            dateStyle: "short", //2024/3/25
        }).format(date);
    }
};
