import CachedRoundedIcon from "@mui/icons-material/CachedRounded";

import MButton from "./MButton";

interface IProps {
    handleClick: () => void;
    btnText: string;
    isDisabled?: boolean;
}

const ReloadButton = ({ handleClick, btnText, isDisabled = false }: IProps) => {
    return (
        <MButton
            btnText={btnText}
            icon={<CachedRoundedIcon viewBox='4 2 22 22' />}
            type='submit'
            color='secondary'
            sx={{ color: "#fbfbfb" }}
            onClick={handleClick}
            disabled={isDisabled}
        />
    );
};

export default ReloadButton;
