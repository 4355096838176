import { Info } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { FormRadio } from "../../../components/form";
import { KNOWLEDGE_RESPONSE_TYPE } from "../types";

const ResponseType = () => {
    const { t } = useTranslation();

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", maxWidth: "800px", gap: "1rem" }}>
                <h2 className='inlineTitle'>{t("KNOWLEDGE.Bot_Res_Type")}</h2>
                <Tooltip
                    title={
                        <Fragment>
                            <p>{t("KNOWLEDGE.Bot_Res_Type_Sum_Info")}</p>
                            <p>{t("KNOWLEDGE.Bot_Res_Type_Full_Info")}</p>
                        </Fragment>
                    }
                    enterTouchDelay={0}
                    sx={{ minWidth: "200px" }}
                    placement='top-start'
                >
                    <Info sx={{ color: (theme) => theme.palette.primary.light }} />
                </Tooltip>
            </Box>
            <div style={{ marginTop: "-2rem" }}>
                <FormRadio
                    name='knowledge_response_type'
                    radioValue={KNOWLEDGE_RESPONSE_TYPE.SUMMARY}
                    label={t("KNOWLEDGE.Bot_Res_Type_Sum")}
                />
                <FormRadio
                    name='knowledge_response_type'
                    radioValue={KNOWLEDGE_RESPONSE_TYPE.FULL}
                    label={t("KNOWLEDGE.Bot_Res_Type_Full")}
                />
            </div>
        </>
    );
};

export default ResponseType;
