import { Checkbox, Paper } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { PageLoader } from "../../../components/loader";
import { customToast } from "../../../components/notify";
import { useUnBindKnowledgeMutation } from "../services";
import { IKnowledge } from "../types";
import styles from "./KnowledgeCard.module.scss";

interface IProps {
    knowledgeItem: IKnowledge;
}
const KnowledgeCard = ({ knowledgeItem }: IProps) => {
    const { projectId } = useParams();
    const navigate = useNavigate();

    const { id, description, title } = knowledgeItem;

    const [unBlindK, { isLoading, isSuccess }] = useUnBindKnowledgeMutation();

    const handleRemoveBind = async () => {
        if (knowledgeItem.project_knowledge_id) {
            await unBlindK({ id: knowledgeItem.project_knowledge_id });
        }
    };

    useEffect(() => {
        if (isSuccess) {
            customToast.success("Remove bind knowledge in project");
        }
    }, [isSuccess]);
    return (
        <Paper className={styles.card}>
            <PageLoader loading={isLoading} />
            <div className={styles.cardBody}>
                <div
                    className={styles.content}
                    onClick={
                        () => navigate(`/projects/${projectId}/knowledge/${id}/knowledge-records`, { state: projectId })
                        //navigate(`/projects/${projectId}/knowledge/${id}/knowledge-records`, )
                    }
                >
                    <div className={styles.cardTitle}>
                        <div className={styles.title}>{title}</div>
                        <Checkbox
                            checked={true}
                            onChange={handleRemoveBind}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        />
                    </div>
                    <div className={styles.desc}>{description}</div>
                </div>
            </div>
        </Paper>
    );
};
export default KnowledgeCard;
