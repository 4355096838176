import { Box } from "@mui/material";
import React from "react";

import CharacterForm from "./CharacterForm";
import styles from "./CreateCharacter.module.scss";

interface IProps {
    isCreate: boolean;
}

const CreateCharacter = ({ isCreate }: IProps) => {
    return (
        <Box>
            <Box className={isCreate ? "" : styles.marginB}>
                <CharacterForm isCreate={isCreate} />
            </Box>
        </Box>
    );
};
export default CreateCharacter;
