import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import BackPage from "../../components/button/BackPage";
import RegularContainer from "../../components/container/RegularContainer";
import InnerTabs from "../../components/tab/InnerTabs";
import { useGetKnowledgeQuery } from "../../features/knowledges/services";
import { ILinkTabProps } from "../../types/interfaces";

const tabRoutes: ILinkTabProps[] = [
    { labelKey: "KNOWLEDGE.Smart_Generator", href: "smart-generator" },
    { labelKey: "KNOWLEDGE.Manual_Generator", href: "manual-generator" },
    { labelKey: "KNOWLEDGE.Knowledge_Setting", href: "knowledge-settings" },
    { labelKey: "KNOWLEDGE.Records_List", href: "knowledge-records" },
    { labelKey: "KNOWLEDGE.Record_Recycle_Bin", href: "knowledge-records-recycle-bin" },
];

const RecordsTabs = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const location = useLocation();
    const { state } = location;
    const { projectId, knowledgeId } = useParams();

    const [currentTab, setCurrentTab] = useState(0);
    const { data: knowledgeInfo } = useGetKnowledgeQuery(
        {
            id: knowledgeId as string,
        },
        { skip: !knowledgeId }
    );
    const knowledgeName = knowledgeInfo?.data?.title;
    const knowledgeLayoutTitle = `${t("RECORD.Manage_Record")} : ${knowledgeName}`;
    const handleChange = (newValue: number) => {
        setCurrentTab(newValue);
    };

    const pathnameSplit = location?.pathname?.split("/");
    const currentRoute = pathnameSplit[pathnameSplit?.length - 1] || "";

    const findIdx = tabRoutes.findIndex((r) => {
        return r.href === currentRoute;
    });
    const backUrl = state ? `/projects/${projectId}/knowledge/inuse-knowledge` : `/projects/${projectId}`;
    useEffect(() => {
        if (findIdx) {
            setCurrentTab(findIdx);
            return;
        }
        setCurrentTab(0);
    }, [findIdx, currentRoute]);

    return (
        <>
            <BackPage path={backUrl} />
            <RegularContainer sx={{ pb: 0 }}>
                <RegularContainer.Heading title={knowledgeLayoutTitle} />
                <RegularContainer.Content>
                    <InnerTabs
                        currentTab={currentTab}
                        handleChange={handleChange}
                        tabRoutes={tabRoutes}
                        tabsOnClick={(tabRoute) => {
                            navigate(`/projects/${projectId}/knowledge/${knowledgeId}/${tabRoute.href}`, {
                                state: projectId,
                            });
                        }}
                    />
                </RegularContainer.Content>
            </RegularContainer>
        </>
    );
};

export default RecordsTabs;
