import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { createContext, Dispatch, SetStateAction, useContext, useState } from "react";
import { Outlet } from "react-router-dom";

export interface IDateProps {
    date: Dayjs | null;
    setDate: Dispatch<SetStateAction<Dayjs | null>>;
}
const DateContext = createContext({} as IDateProps);

dayjs.extend(utc);

export const useDateContext = () => useContext(DateContext);

export const DateProvider = () => {
    const [date, setDate] = useState<Dayjs | null>(dayjs.utc());

    return (
        <DateContext.Provider value={{ date: date, setDate }}>
            <Outlet />
        </DateContext.Provider>
    );
};
