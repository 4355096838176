import { Skeleton } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { FormRadioUnControl } from "../../../../components/form";
import { BouncingDots } from "../../../../components/loader";
import { CHAT_WIDGET_VERSION } from "../../../../types/enum";
import { CHATROOM_OPTIONS } from "../../constants";
import { useGenerateChatWidgetConfig } from "../../hooks";
import ChatIFrame from "./ChatIFrame";
import styles from "./ChatroomPreview.module.scss";

const ChatroomPreview = () => {
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState<CHAT_WIDGET_VERSION>(CHAT_WIDGET_VERSION.MOBILE);
    const { characterId } = useParams();

    const widgetConfig = useGenerateChatWidgetConfig({
        characterId,
        selectorId: "chat-widget-preview",
    });

    const isMobile = selectedValue === "mobile";
    return (
        <>
            <h3 style={{ marginTop: "0rem", marginBottom: "1rem" }} className='inlineTitle'>
                {t("CHATROOM.PreviewVersion")}
            </h3>
            <FormRadioUnControl
                radioValue={CHATROOM_OPTIONS}
                setSelectedValue={setSelectedValue}
                selectedValue={selectedValue}
            />
            <div className={styles.wrapper}>
                {widgetConfig ? (
                    <div className={clsx(styles.chatWidgetContainer, { [styles.chatWidgetMobile]: isMobile })}>
                        <div className={clsx(styles.skeletonWrapper, { [styles.none]: isMobile })}>
                            <Skeleton
                                height='70px'
                                width='100%'
                                variant='rectangular'
                                classes={{ root: clsx(styles.skeleton) }}
                            />
                            <Skeleton
                                height='200px'
                                width='100%'
                                variant='rectangular'
                                classes={{ root: clsx(styles.skeleton) }}
                            />
                            <Skeleton
                                height='200px'
                                width='20%'
                                variant='rectangular'
                                classes={{ root: clsx(styles.skeleton) }}
                            />
                            <Skeleton
                                height='200px'
                                width='40%'
                                variant='rectangular'
                                classes={{ root: clsx(styles.skeleton) }}
                            />
                            <Skeleton
                                height='200px'
                                width='30%'
                                variant='rectangular'
                                classes={{ root: clsx(styles.skeleton) }}
                            />
                        </div>
                        <ChatIFrame chatWidgetVersion={selectedValue} chatWidgetConfig={widgetConfig}>
                            <div id='chat-widget-root'></div>
                        </ChatIFrame>
                    </div>
                ) : (
                    <div className={styles.widgetLoader}>
                        <BouncingDots />
                    </div>
                )}
            </div>
        </>
    );
};

export default ChatroomPreview;
