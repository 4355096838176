import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import clsx from "clsx";
import { ReactNode, useEffect, useRef, useState } from "react";

import styles from "./ScrollBox.module.scss";

interface IProps {
    children: ReactNode;
}
const scrollOffset = 400;
const ScrollBox = ({ children }: IProps) => {
    const scrollableRef = useRef<HTMLDivElement>(null);
    const [isScrollVisible, setIsScrollVisible] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const currentRef = scrollableRef?.current;

        const onScroll = (event: Event) => {
            if (currentRef && currentRef?.scrollLeft > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        if (currentRef && scrollableRef.current.scrollWidth > scrollableRef.current.clientWidth) {
            currentRef.addEventListener("scroll", onScroll);
            setIsScrollVisible(true);
            return;
        }
        setIsScrollVisible(false);

        return () => {
            if (currentRef) {
                currentRef.removeEventListener("scroll", onScroll);
            }
        };

        // let react rerender when switch project
    }, [children]);

    const handleScroll = (offset: number) => {
        if (scrollableRef?.current) {
            scrollableRef.current.style.scrollBehavior = "smooth";
            scrollableRef.current.scrollLeft += offset;
        }
    };

    return (
        <div className={styles.wrapper}>
            {isScrollVisible && isScrolled && (
                <IconButton
                    className={clsx(styles.controlIcon, styles.controlLeft)}
                    onClick={() => handleScroll(-scrollOffset)}
                >
                    <ChevronLeft sx={{ fontSize: 40 }} />
                </IconButton>
            )}
            <div className={styles.box} ref={scrollableRef}>
                {/*<div className={styles.content}> {children}</div>*/}
                {children}
            </div>
            {isScrollVisible && (
                <IconButton
                    className={clsx(styles.controlIcon, styles.controlRight)}
                    onClick={() => handleScroll(scrollOffset)}
                >
                    <ChevronRight sx={{ fontSize: 40 }} />
                </IconButton>
            )}
        </div>
    );
};
export default ScrollBox;
