import { apiSlice } from "../../../services/api";
import { IGetListRequest, IGetListResponse } from "../../../types/interfaces";
import {
    IBindKnowledgeReq,
    ICreateKnowledgeReq,
    ICreateKRes,
    IDefaultK,
    IDeleteKnowledgeReq,
    IExportFileReq,
    IExportFileRes,
    IGetProjectKnowledgeReq,
    IImportFileRes,
    IKnowledge,
    IKnowledgeRes,
    IQueryKnowledgeParams,
    IUnbindKnowledgeReq,
    IUpdateKnowledgeReq,
} from "../types";

export const knowledgesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createKnowledge: builder.mutation<ICreateKRes, ICreateKnowledgeReq>({
            query: (reqData) => ({
                url: "/knowledge/create_knowledge",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Knowledge"],
        }),
        updateKnowledge: builder.mutation<IKnowledgeRes, IUpdateKnowledgeReq>({
            query: (reqData) => ({
                url: "/knowledge/update_knowledge",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Knowledge"],
        }),
        getUnbindKnowledges: builder.query<IGetListResponse<IKnowledge[]>, IGetListRequest>({
            query: (reqData) => ({
                url: "/knowledge/unbind_knowledges",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["Knowledge"],
        }),
        getKnowledge: builder.query<IKnowledgeRes, IQueryKnowledgeParams>({
            query: (params) => ({
                url: "/knowledge/get_record",
                params,
            }),
            providesTags: ["Knowledge"],
        }),
        getAllKnowledgesByProjects: builder.query<IGetListResponse<IKnowledge[]>, IGetListRequest>({
            query: (reqData) => ({
                url: "/knowledge/project_knowledges",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["Knowledge"],
        }),
        getOfficialKnowledges: builder.query<IGetListResponse<IDefaultK[]>, IGetListRequest>({
            query: (reqData) => ({
                url: "/knowledge/query_official_knowledge",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["Knowledge"],
        }),
        getDefaultKnowledges: builder.query<IGetListResponse<IDefaultK[]>, IGetListRequest>({
            query: (reqData) => ({
                url: "/knowledge/query_default_knowledge",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["Knowledge"],
        }),
        deleteKnowledge: builder.mutation<{ success: boolean }, IDeleteKnowledgeReq>({
            query: (reqData) => ({
                url: "/knowledge/delete_knowledge",
                method: "DELETE",
                body: { ...reqData },
            }),
            invalidatesTags: ["Knowledge"],
        }),
        bindKnowledge: builder.mutation<{ code: number; msg: string }, IBindKnowledgeReq>({
            query: (reqData) => ({
                url: "/knowledge/bind_knowledge_to_project",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Knowledge"],
        }),
        copyKnowledge: builder.mutation<{ code: number; msg: string }, IBindKnowledgeReq>({
            query: (reqData) => ({
                url: "/copy_knowledge_to_team",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Knowledge"],
        }),
        unBindKnowledge: builder.mutation<{ code: number; msg: string }, IUnbindKnowledgeReq>({
            query: (reqData) => ({
                url: "/knowledge/remove_knowledge_from_project",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Knowledge"],
        }),
        // get my knowledge in project
        myProjectKnowledge: builder.query<
            { code: number; msg: string; data: IKnowledge[] | null },
            IGetProjectKnowledgeReq
        >({
            query: (reqData) => ({
                url: "/knowledge/project_knowledge_of_mine",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["Knowledge"],
        }),
        // get public knowledge in project
        otherProjectKnowledges: builder.query<
            { code: number; msg: string; data: IKnowledge[] | null },
            IGetProjectKnowledgeReq
        >({
            query: (reqData) => ({
                url: "/knowledge/project_knowledge_of_others",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["Knowledge"],
        }),
        //export knowledge
        inuseKnowledgesExport: builder.mutation<IExportFileRes, IExportFileReq>({
            query: (reqData) => ({
                url: "/project/export_knowledge",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["KnowledgeExport"],
        }),
        // import knowledge to project
        importKnowledge: builder.mutation<IImportFileRes, FormData>({
            query: (formData) => ({
                url: "/project/import_knowledge",
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Knowledge"],
        }),
    }),
});

export const {
    useGetUnbindKnowledgesQuery,
    useCreateKnowledgeMutation,
    useUpdateKnowledgeMutation,
    useDeleteKnowledgeMutation,
    useGetAllKnowledgesByProjectsQuery,
    useGetKnowledgeQuery,
    useGetDefaultKnowledgesQuery,
    useGetOfficialKnowledgesQuery,
    useBindKnowledgeMutation,
    useUnBindKnowledgeMutation,
    useMyProjectKnowledgeQuery,
    useOtherProjectKnowledgesQuery,
    useCopyKnowledgeMutation,
    useInuseKnowledgesExportMutation,
    useImportKnowledgeMutation,
} = knowledgesApiSlice;
