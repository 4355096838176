import React from "react";

import DashboardPage from "../pages/projects/DashboardPage";
import ProjectTabs from "../pages/projects/ProjectTabs";
import AnalysisRoutes from "./AnalysisRoutes";
import CharacterRoutes from "./CharacterRoutes";
import KnowledgeRoutes from "./KnowledgeRoutes";
const VoiceKeywordPage = React.lazy(() => import("../pages/projects/VoiceKeywordPage"));
const HomophoneFixPage = React.lazy(() => import("../pages/projects/HomophoneFixPage"));
const ProjectSettingsPage = React.lazy(() => import("../pages/projects/ProjectSettingsPage"));
const ScriptTestPage = React.lazy(() => import("../pages/projects/ScriptTestPage"));
const ProjectRoutes = [
    {
        path: ":projectId",
        children: [
            {
                path: "",
                element: <ProjectTabs />,
                children: [
                    { path: "", element: <DashboardPage /> },
                    { path: "settings", element: <ProjectSettingsPage /> },
                    { path: "data-analysis", children: AnalysisRoutes },
                    { path: "voice-keywords", element: <VoiceKeywordPage /> },
                    { path: "homophone-fix", element: <HomophoneFixPage /> },
                    { path: "script-test", element: <ScriptTestPage /> },
                ],
            },
            {
                path: "knowledge",
                children: KnowledgeRoutes,
            },
            {
                path: "characters",
                children: CharacterRoutes,
            },
        ],
    },
];

export default ProjectRoutes;
