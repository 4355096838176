import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { InputProps, OutlinedInputProps, TextField, TextFieldProps } from "@mui/material";
import clsx from "clsx";
import { ComponentPropsWithoutRef, useState } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classes from "./FormInput.module.scss";

type IFormInputProps = {
    name?: string;
    setValue?: (value: unknown) => void;
    type?: "text" | "number" | "password" | "email";
    otherInputProps?: TextFieldProps;
    InputProps?: Partial<OutlinedInputProps | InputProps>;
} & ComponentPropsWithoutRef<typeof TextField>;

export const MInput = (inputProps: IFormInputProps) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const { type = "text", required = true, onChange, value, variant, label, ...otherInputProps } = inputProps;
    return (
        <TextField
            onChange={onChange}
            value={value}
            fullWidth
            required={required}
            type={type === "password" ? (showPassword ? "text" : "password") : type}
            variant={variant}
            size='small'
            label={label}
            className={clsx(classes.customInput)}
            InputProps={{
                ...(type === "password" && {
                    endAdornment: (
                        <div
                            className={classes.controlShowPassword}
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}
                        >
                            {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                        </div>
                    ),
                }),
            }}
            {...(variant === "filled" && {
                InputProps: { disableUnderline: true },
            })}
            {...otherInputProps}
        />
    );
};
const FormInput = (inputProps: IFormInputProps) => {
    const { name, error, ...otherInputProps } = inputProps;
    const { t } = useTranslation();

    const { control } = useFormContext<FieldValues>();
    return (
        <Controller
            name={name || "unknown"}
            control={control}
            defaultValue={""}
            render={({ field: { onChange, value, onBlur }, fieldState: { error }, formState }) => {
                return (
                    <MInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        helperText={error ? t(error?.message as string) : null}
                        error={!!error}
                        {...otherInputProps}
                    />
                );
            }}
        />
    );
};
export default FormInput;
