import { customToast } from "../../../components/notify";
import { useDeleteKnowledgeMutation } from "../services";

interface IProps {
    knowledgeId: string | undefined;
}

const useDeleteKnowledge = ({ knowledgeId }: IProps) => {
    const [deleteK, { isLoading: delKLoading, isSuccess: delKSuccess }] = useDeleteKnowledgeMutation();
    const handleDelete = async () => {
        try {
            if (!knowledgeId) return;
            await deleteK({ id: knowledgeId }).unwrap();
        } catch {
            customToast.error("Failed");
        }
    };
    return {
        isSuccess: delKSuccess,
        isLoading: delKLoading,
        handleDelete,
    };
};
export default useDeleteKnowledge;
