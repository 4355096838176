export enum KNOWLEDGE_CATEGORY_TYPE {
    "newest" = "newest",
    "popular" = "popular",
    "銷售" = "銷售",
    "客服" = "客服",
    "觀光" = "觀光",
}

// knowledge_response_type

export enum KNOWLEDGE_RESPONSE_TYPE {
    "SUMMARY" = 0,
    "FULL" = 1,
}
