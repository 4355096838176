import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { customToast } from "../../../components/notify";
import { IUploadFileInput } from "../../records/types";
import { useImportKnowledgeMutation } from "../services";

const useKnowledgeImport = () => {
    const { projectId } = useParams();
    const [importExcel, { isLoading: importLoading }] = useImportKnowledgeMutation();

    const methods = useForm<IUploadFileInput>({
        defaultValues: { textFile: undefined },
        mode: "onBlur",
    });

    const onSubmit = (data: IUploadFileInput) => {
        if (!projectId) return;
        const formData = new FormData();
        const fields = {
            project_id: projectId,
        };
        Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
        });
        data.textFile?.name && formData.append("file", data.textFile);
        importExcel(formData);
        customToast.success("It would take few seconds, please wait.");
        methods.reset();
    };
    useEffect(() => {
        //初始 reset，確保把檔案上傳欄位的初始值變為 undefined，以符合按鈕的 disabled 條件
        methods.reset();
    }, [methods]);

    return { methods, importLoading, formSubmit: methods.handleSubmit(onSubmit) };
};
export default useKnowledgeImport;
