import { SyntheticEvent, useState } from "react";

import { useAppSelector, useToggle } from "../../../hooks";
import { KNOWLEDGE_PAGE_MODE } from "../../../types/enum";
import { selectCurrentUser } from "../../authentication/services";
import { useGetKnowledgeQuery } from "../../knowledges/services";

interface IProps {
    knowledgeId: string | undefined;
    mode: KNOWLEDGE_PAGE_MODE;
}
const checkIsEditable = (mode: KNOWLEDGE_PAGE_MODE, isOwnKnowledge: boolean): boolean => {
    let isEditable = true;
    if (mode === KNOWLEDGE_PAGE_MODE.PREVIEW && !isOwnKnowledge) {
        isEditable = false;
    }
    return isEditable;
};

const useKnowledge = ({ knowledgeId, mode }: IProps) => {
    const currentUser = useAppSelector(selectCurrentUser);

    const [expanded, setExpanded] = useState<string | false>(false);
    const [editMode, setEditMode] = useToggle(false);
    const [settingDialog, setSettingDialog] = useToggle(false);

    // ======= api =======
    const { data: knowledgeInfo } = useGetKnowledgeQuery(
        {
            id: knowledgeId as string,
        },
        { skip: !knowledgeId }
    );

    const isOwnKnowledge = knowledgeInfo?.data?.account_id === currentUser?.accountId;

    const editable = checkIsEditable(mode, isOwnKnowledge);

    const handlePanelChange = (panel: string) => (e: SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return {
        // isLoading: kLoading,
        editMode,
        setEditMode,
        knowledgeInfo: knowledgeInfo?.data,
        setSettingDialog,
        settingDialog,
        expanded,
        handlePanelChange,
        editable,
    };
};
export default useKnowledge;
