import { Add, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import ScrollBox from "../../../../components/box/ScrollBox";
import MButton from "../../../../components/button/MButton";
import HeadingWithInfo from "../../../../components/heading/HeadingWithInfo";
import MAlert from "../../../../components/notify/MAlert";
import { KnowledgeCard } from "../../../knowledges/components";
import { useMyProjectKnowledgeQuery } from "../../../knowledges/services/knowledgesApiSlice";
import classes from "./PMyKnowledge.module.scss";

const PMyKnowledge = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { projectId } = useParams();

    const { data: knowledgeData } = useMyProjectKnowledgeQuery(
        {
            project_id: projectId as string,
            page: 1,
            limit: 100,
        },
        { skip: !projectId, refetchOnMountOrArgChange: true }
    );

    return (
        <div className={classes.section}>
            <div className={classes.heading}>
                <HeadingWithInfo title={t("DASHBOARD.MY_KNOWLEDGES")} info={t("DASHBOARD.MY_KNOWLEDGES_HELP")} />
                <div className={classes.actions}>
                    <MButton
                        btnText={t("KNOWLEDGE.Manage_Knowledge")}
                        icon={<Search />}
                        sx={{ maxWidth: "160px" }}
                        fullWidth
                        color='secondary'
                        handleClick={() =>
                            navigate(`/projects/${projectId}/knowledge/inuse-knowledge`, { state: projectId })
                        }
                    />
                    <MButton
                        btnText={t("KNOWLEDGE.Create_Knowledge")}
                        icon={<Add />}
                        sx={{ maxWidth: "160px" }}
                        fullWidth
                        handleClick={() =>
                            navigate(`/projects/${projectId}/knowledge/create-knowledge`, { state: projectId })
                        }
                    />
                </div>
            </div>

            <ScrollBox key={projectId}>
                {knowledgeData?.data ? (
                    knowledgeData?.data?.map((item) => {
                        return (
                            <div key={item.id}>
                                <KnowledgeCard key={item.id} knowledgeItem={item} />
                            </div>
                        );
                    })
                ) : (
                    <MAlert severity='info'>{t("DASHBOARD.NO_MY_KNOWLEDGES")}</MAlert>
                )}
            </ScrollBox>
        </div>
    );
};
export default PMyKnowledge;
