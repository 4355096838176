import { useTranslation } from "react-i18next";
import { boolean, number, object, string } from "yup";

import { PROMPT_WORD_LIMIT } from "../constant.ts";

export const KnowledgeInfoSchema = () => {
    const { t } = useTranslation();

    const schema = {
        title: string().required("COMMON.Required_Input"),
        description: string()
            .required("COMMON.Required_Input")
            .max(PROMPT_WORD_LIMIT, `${t("COMMON.Word_Limit")} ${PROMPT_WORD_LIMIT}`),
        source_file_url: string(),
        source_knowledge_id: string(),
        public: boolean().required(),
        project_id: string().required(),
        knowledge_response_type: number().required(),
    };

    return object().shape(schema);
};

const pattern = new RegExp(
    "^(https:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and pathscratch
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
);

export const importUrlSchema = () => {
    const schema = {
        inputUrl: string().test("input-url", "錯誤訊息", function (value, ctx) {
            if (value === "") return true;
            if (!value?.match(pattern)) return ctx.createError({ message: "請輸入正確網址格式" });
            return true;
        }),
    };
    return object().shape(schema);
};

export const manualGeneratorSchema = (contentLimit: number) => {
    const schema = {
        title: string().required("請輸入內文標題"),
        content: string().required("請輸入內文內容").max(contentLimit),
        inputUrl: string().test("input-url", "錯誤訊息", function (value, ctx) {
            if (value === "") return true;
            if (!value?.match(pattern)) return ctx.createError({ message: "請輸入正確網址格式" });
            return true;
        }),
        keywordInput: string().test("keyword-input", "over limit", function (value, ctx) {
            if (!value) return true;
            if (/[\u4e00-\u9fa5]/.test(value)) {
                return value.length <= 5 ? true : ctx.createError({ message: "超過字數限制" });
            } else {
                const words = value.split(" ");
                return words.length <= 2 ? true : ctx.createError({ message: "Over word count limit" });
            }
        }),
    };
    return object().shape(schema);
};
