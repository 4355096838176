import { apiSlice } from "../../../services/api/apiSlice";
import { IApiGetOneParams, IApiQueryParams } from "../../../types/interfaces";
import {
    IBindCharacter,
    ICharacterRes,
    ICharactersRes,
    IChatHistoryRes,
    ICreateCharacterReq,
    IDeleteCharacterReq,
    IGetProjectCharacterReq,
    INewCharacterRes,
    IPromptListRes,
    IUpdateCharacterReq,
    IUploadGreetingFileRes,
} from "../types/interfaces";

export const charactersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        //> 建立角色
        createCharacter: builder.mutation<INewCharacterRes, ICreateCharacterReq>({
            query: (reqData) => ({
                url: "/create_character",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["ProjectCharacter"],
        }),
        //> 編輯角色
        updateCharacter: builder.mutation<{ success: boolean; projects: string }, IUpdateCharacterReq>({
            query: (reqData) => ({
                url: "/update_character",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["ProjectCharacter"],
        }),
        //> 取得所有公開角色
        getAllPublicCharacters: builder.query<ICharactersRes, IApiQueryParams>({
            query: (params) => ({
                url: "/public_characters",
                params,
            }),
            providesTags: ["ProjectCharacter"],
        }),
        //> 取得專案角色
        getAllProjectCharacters: builder.query<ICharactersRes, IGetProjectCharacterReq>({
            query: (reqData) => ({
                url: "/project_characters",
                method: "POST",
                body: { ...reqData },
            }),
            providesTags: ["ProjectCharacter"],
        }),
        //> 取得角色資訊
        getCharacter: builder.query<ICharacterRes, IApiGetOneParams>({
            query: (params) => ({
                url: "/character",
                params,
            }),
            providesTags: ["ProjectCharacter"],
        }),
        //> 刪除角色
        deleteCharacter: builder.mutation<{ success: boolean }, IDeleteCharacterReq>({
            query: (reqData) => ({
                url: "/delete_character",
                method: "DELETE",
                body: { ...reqData },
            }),
            invalidatesTags: ["ProjectCharacter"],
        }),
        //> copy character to project
        bindCharacter: builder.mutation<{ success: boolean }, IBindCharacter>({
            query: (reqData) => ({
                url: "/copy_character",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["ProjectCharacter"],
        }),
        //> 上傳角色 Avatar
        uploadAvatar: builder.mutation({
            query: (formData) => ({
                url: "/upload_avatar",
                method: "POST",
                body: formData,
                prepareHeaders: (headers: Headers) => {
                    headers.set("Content-Type", "multipart/form-data");
                    return headers;
                },
            }),
        }),
        // > get default character prompt list
        promptList: builder.query<IPromptListRes, void>({
            query: () => ({
                url: "/select_prompt_list",
                method: "POST",
            }),
            providesTags: ["DefaultPrompts"],
        }),
        // > 上傳招呼詞檔案
        uploadGreetingFile: builder.mutation<IUploadGreetingFileRes, FormData>({
            query: (formData) => ({
                url: "/copywriting/import",
                method: "POST",
                body: formData,
                prepareHeaders: (headers: Headers) => {
                    headers.set("Content-Type", "multipart/form-data");
                    return headers;
                },
            }),
            invalidatesTags: ["ProjectCharacter"],
        }),
        // > 下載角色對話紀錄
        downloadChatHistory: builder.mutation<IChatHistoryRes, IBindCharacter>({
            query: (reqData) => ({
                url: "/chat_history/export",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["ChatHistory"],
        }),
    }),
});

export const {
    useCreateCharacterMutation,
    useUpdateCharacterMutation,
    useDeleteCharacterMutation,
    useGetAllPublicCharactersQuery,
    useGetCharacterQuery,
    useGetAllProjectCharactersQuery,
    useBindCharacterMutation,
    useUploadAvatarMutation,
    usePromptListQuery,
    useUploadGreetingFileMutation,
    useDownloadChatHistoryMutation,
} = charactersApiSlice;
